.shopify-icon {
  background: url(../../assets/shopify.svg) center center no-repeat;
  background-size: 84px 30px;
  background-position: left;
}

.zendesk-icon {
  background: url(../../assets/zendesk.svg) center center no-repeat;
  background-size: 78px 21px;
  background-position: left;
}
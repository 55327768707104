.item {
  margin-right: 8px;
  margin-bottom: 8px;
  object-fit: contain;
  object-position: center;
  border-radius: 8px;
  cursor: pointer;
  max-width: 300px;
  max-height: 500px;
}

.item:nth-child(3n) {
  margin-right: 0;
}

.img-2 .item:nth-child(2n),
.img-4 .item:nth-child(2n) {
  margin-right: 0;
}

.img-4 .item:nth-child(3n) {
  margin-right: 8px;
}
.settingsModal {
	max-width: 32.5rem !important;
	height: 400px;
}

.settingTitle {
	line-height: 21px;
	font-size: 0.875rem;
}

.settingsTip {
	line-height: 1.125rem;
	font-size: 0.75rem;
}

.policy {
	font-size: 0.75rem;
	line-height: 1.125rem;
}

.projectName {
	font-size: 0.875rem;
	line-height: 2.5rem;
}

.modal {
  max-width: 720px !important;
	height: 540px;
  border-radius: 12px !important;
  padding: 0 !important;
  overflow-y: auto;
}
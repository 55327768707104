
.commonIcon {
  @apply w-4 h-4 inline-block align-middle;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.actionIcon {
  @apply bg-gray-500;
  mask-image: url(~@/assets/action.svg);
}
.actionItem {
  @apply h-8 py-[6px] px-3 mx-1 flex items-center gap-2 hover:bg-gray-100 rounded-lg cursor-pointer;
  width: calc(100% - 0.5rem);
}
.deleteActionItem {
  @apply hover:bg-red-50 !important;
}
.actionName {
  @apply text-gray-700 text-sm;
}

/* .completionPic {
  background-image: url(~@/app/components/app-sidebar/completion.png)
}
    
.expertPic {
  background-image: url(~@/app/components/app-sidebar/expert.png)
} */

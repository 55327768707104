.wrapper {
  border-color: #d0d5dd;
}

.checked {
  background: #38BE89 url(./assets/check.svg) center center no-repeat;
  background-size: 12px 12px;
  border-color: #38BE89;
}

.checked.disabled {
  background-color: #d0d5dd;
  border-color: #d0d5dd;
}

.settingTitle {
	line-height: 21px;
	font-size: 0.875rem;
}

.projectName {
	font-size: 0.875rem;
	line-height: 2.5rem;
}

.basicInfoContainer::-webkit-scrollbar {
  display: none;
}